import * as React from "react"
import { useState, useRef, useEffect } from "react";
import useIntersection from "../components/userintersection"
import { StaticImage } from "gatsby-plugin-image"
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container'
import * as style from "./styles.module.scss"
import classNames from 'classnames';
import Homeheader from "../components/home_header/index"
import Sectionheaderlight from "../components/typography/section_headers/light"
import Sectionheaderdark from "../components/typography/section_headers/dark"

import Card from '@material-ui/core/Card'
import Cardcontent from '@material-ui/core/CardContent'

import Chip from '@material-ui/core/Chip';

import School from '@material-ui/icons/SchoolSharp';
import Slide from '@material-ui/core/Slide';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import { useScrollTrigger } from "@material-ui/core";
import Topnav from "../components/top_nav/index"
import mainlogo from "../images/mainlogo.svg"
import MenuIcon1 from "@material-ui/icons/Menu"
import Lock from "@material-ui/icons/Lock"
import WatchLater from "@material-ui/icons/WatchLater"
import { Layers, AllInclusive, Speaker, Book } from "@material-ui/icons/"
import Location from "@material-ui/icons/LocationCity"
import MobMenu from "../components/mobmenu";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Contact from "../components/contact"
import { Link } from "gatsby"
import Fullnav from "../components/fullnav"
import Footer from "../components/footer";
import CaseHeader from "../components/case_header";
import caseimg from "../images/projects/xm.png"
import caseimg1 from "../images/projects/probono.png"
import caseimg2 from "../images/relationship.png"
import blueheaderimg from "../images/blueheader_img.png"







// function HideOnScroll(props) {
//   const { children, window } = props;
//   // Note that you normally won't need to set the window ref as useScrollTrigger
//   // will default to window.
//   // This is only being set here because the demo is in an iframe.
//   const trigger = useScrollTrigger({ threshold: 300 });
//   console.log(" i am called")
//   return (
//     <Slide appear={false} direction="down" in={!trigger}>
//       <div className={style.pf_slider_top_nav}>
//         {children}
//       </div>
//     </Slide>
//   );
// }


// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   menuButton: {
//     // marginRight: theme.spacing(2),
//   },
//   title: {
//     flexGrow: 1,
//   },
// }));



export default function IndexPage(props) {

  // const [navbar, setNav] = useState(false)

  // const changeBackground = () => {
  //   // console.log(window.scrollY)
  //   if (window.scrollY >= 100) {
  //     setNav(true);
  //   }
  //   else setNav(false);
  // }
  // useEffect(() => {
  //   window.addEventListener('scroll', changeBackground)
  // });


  // const classes = useStyles();
  return (

    <div id="home" >
      <Fullnav></Fullnav>

      <Container className={classNames(style.pf_full_width, style.blueheader)}>
        <Container>
          <div className={style.blueheadgrid}>
            <div>

              👋 Hello! I am Vinay Shanthagiri
              <h1>
                I help product teams Reason, Visualize, Plan & Build user friendly products & services
          </h1>
              <p>
                I am a seasoned UX Designer who believes in simplifying the Human & Machine interactions with thoughtful, elegant and effective user experience.
                I am passionate about new technologies and often spend time understanding their impacts on UX.
          </p>
              <p>
                As you scroll down you will know more about my journey, and if you find something interesting, you can always connect with me on <a target="_blank" href="https://www.linkedin.com/in/vinayshanthagiri">Linkedin!</a>

              </p>
            </div>
            <div className={style.blueheaderimg}>
              <StaticImage
                alt="Vinay Shanthagiri UX Designer"
                src="../images/blueheader_img.png"
                placeholder="blurred"
              />
            </div>

          </div>
        </Container>
      </Container>


      <Container id="past" maxWidth={false} className={classNames(style.pf_block_pads, style.pf_grey)}>
        <Sectionheaderlight caption="Selected" label="Case Studies" >
          {/* <div className={style.pf_info}>
            Due to NDA, all the case studies below will focus solely on the problem, approach and solution with fully anonymized data
          </div> */}
        </Sectionheaderlight>
        {/* //Gridblock code */}
        <Container maxWidth="lg" className={style.pf_block_pads_top}>

          <div className={style.pf_assymetric_grid}>

            <Link to="/remote">
              <CaseHeader label="Developer Experience"
                description="Quick case study on how we built a unified Developer portal for an established tech company with a focus on connecting the developers to standardized resources, guidelines and templates. "
                home={true}
                img={caseimg}
                button
              ></CaseHeader>
            </Link>

            <Link to="/probono">
              <CaseHeader label="IA & Interactive Wireframes"
                description="This case study is about a pro bono project for a local business owner. The objective of this project revolves around helping the client decide the content structure and Wireframes for redesign and rebranding of existing site  "
                home={true}
                img={caseimg1}
                button
              ></CaseHeader>
            </Link>
            <Link to="/relationship">
              <CaseHeader label="Remote Relationships"
                description="Remote relationships are hard for both partners involved as distance can cause several problems if not consistently given enough attention. An effective way to be aware of the communication gaps is imperative and useful for partners involved in a remote relationship. "
                home={true}
                img={caseimg2}

                button
              ></CaseHeader>
            </Link>









          </div>
        </Container>

      </Container>
      <Container id="current" maxWidth="lg" className={classNames(style.pf_block_pads, style.pf_white_bg)}>
        <Sectionheaderlight caption="Currently" label="Working on" ></Sectionheaderlight>


        {/* //Two block code */}
        <div className={classNames(style.pf_two_block_grid)}>

          <div>
            <Chip className={style.pf_headingChip} label="UX & Strategy" icon={<Layers />}></Chip>
            <p >Currently working on enhancing the overall Developer Experience for a retail giant through an unified platform which connects the developers to the tools and resources needed to accelerate, optimize and ship quality code.  </p>
          </div>
          <div>
            <Chip className={style.pf_headingChip} label="AI & UX Symbiosis" icon={<AllInclusive />}></Chip>
            <p >Exploring the needs and ways for AI & UX to mutually benefit each other. Advocating - "Better UX for better AI. Better AI for better UX"  </p>
            <Button href="https://www.youtube.com/watch?v=IOVBPq9raD8&t=10s" target="_blank" className={classNames(style.pf_round_bt, style.pf_button_dark_blue)} variant="contained" color="primary">Watch my recent talk on AI & UX</Button>

          </div>
          <div>
            <Chip className={style.pf_headingChip} label="UX & Data Centric AI" icon={<Book />}></Chip>
            <p >Working on an exploratory article investigating the importance of UX in a widely popular Data Centric AI approach (Coming soon) </p>
            {/* <Button href="https://www.youtube.com/watch?v=IOVBPq9raD8&t=10s" target="_blank" className={classNames(style.pf_round_bt, style.pf_button_dark_blue)} variant="contained" color="primary">Watch my recent talk on AI & UX</Button> */}

          </div>



        </div>


      </Container>


      <Container id="journey" maxWidth={false} className={classNames(style.pf_block_pads, style.pf_dark_grey_blue, style.pf_nopad_LR)}>
        <Sectionheaderdark caption="Pitstops in" label=" My journey" className={style.pf_all_white_text} ></Sectionheaderdark>
        {/* //Grid carousel code */}
        <Container maxWidth={false} className={classNames(style.pf_nopad_LR)}>
          <div className={style.pf_symetric_grid}  >

            <div className={style.pf_gridify}>
              <Chip className={style.pf_nobg_chip} label="2016 - Present"  ></Chip>
              <Card className={style.pf_mainCard}>
                <Cardcontent>
                  <div className={style.pf_work_container_grid}>
                    <div className={style.pf_work_header_grid}>
                      <div className={style.pf_work_logo}>
                        <StaticImage className={classNames()}
                          alt="Slalom"
                          src="../images/work/slalom.png"
                          placeholder="blurred"
                        />
                      </div>
                      <div >
                        <h4>Slalom Consulting</h4>
                      </div>
                    </div>
                    <div className={style.pf_work_description}>
                      <h4>Senior Ux Consultant</h4>
Helping clients around the Bay Area to efficiently visualize, reason, plan and build user friendly products and services </div>
                    <div className={style.pf_work_location}>
                      <p>California, USA</p>

                    </div>
                  </div>
                </Cardcontent>
              </Card>
            </div>
            <div className={style.pf_gridify}>
              <Chip className={style.pf_nobg_chip} label="2014"  ></Chip>
              <Card className={style.pf_mainCard}>
                <Cardcontent>
                  <div className={style.pf_work_container_grid}>
                    <div className={style.pf_work_header_grid}>
                      <div className={style.pf_work_logo}>
                        <StaticImage className={classNames()}
                          alt="Slalom"
                          src="../images/work/Sap.png"
                          placeholder="blurred"
                        />
                      </div>
                      <div >
                        <h4>SAP Ariba</h4>
                      </div>
                    </div>
                    <div className={style.pf_work_description}>
                      <h4>Interaction Designer</h4>
Built low/high fidelity interactive prototypes. Standardized interaction patterns & components across products for reusability.
                  </div>
                    <div className={style.pf_work_location}>
                      <p>Bangalore, India</p>

                    </div>
                  </div>
                </Cardcontent>
              </Card>
            </div>
            <div className={style.pf_gridify}>
              <Chip className={style.pf_nobg_chip} label="2012 - 2013"  ></Chip>
              <Card className={style.pf_mainCard}>
                <Cardcontent>
                  <div className={style.pf_work_container_grid}>
                    <div className={style.pf_work_header_grid}>
                      <div className={style.pf_work_logo}>
                        <StaticImage className={classNames()}
                          alt="Slalom"
                          src="../images/work/Fico.png"
                          placeholder="blurred"
                        />
                      </div>
                      <div >
                        <h4>Fair Isaac</h4>
                      </div>
                    </div>
                    <div className={style.pf_work_description}>
                      <h4>UX Engineer</h4>
                      Planned and conducted usability tests.
Built click-able HTML prototypes  for web and mobile apps.
                  </div>
                    <div className={style.pf_work_location}>
                      <p>Bangalore, India</p>

                    </div>
                  </div>
                </Cardcontent>
              </Card>
            </div>
            <div className={style.pf_gridify}>
              <Chip className={style.pf_nobg_chip} label="2011 - 2012"  ></Chip>
              <Card className={style.pf_mainCard}>
                <Cardcontent>
                  <div className={style.pf_work_container_grid}>
                    <div className={style.pf_work_header_grid}>
                      <div className={style.pf_work_logo}>
                        <StaticImage className={classNames()}
                          alt="Slalom"
                          src="../images/work/Pipal.png"
                          placeholder="blurred"
                        />
                      </div>
                      <div >
                        <h4>Pipal Labs</h4>
                      </div>
                    </div>
                    <div className={style.pf_work_description}>
                      <h4>UI Designer</h4>
                      Designed web and mobile applications.
Planned and conducted user research,competitive analysis and usability tests.
                  </div>
                    <div className={style.pf_work_location}>
                      <p>Bangalore, India</p>

                    </div>
                  </div>
                </Cardcontent>
              </Card>
            </div>
            <div className={style.pf_gridify}>
              <Chip className={style.pf_nobg_chip} label="2010 - 2011"  ></Chip>
              <Card className={style.pf_mainCard}>
                <Cardcontent>
                  <div className={style.pf_work_container_grid}>
                    <div className={style.pf_work_header_grid}>
                      <div className={style.pf_work_logo}>
                        <StaticImage className={classNames()}
                          alt="Slalom"
                          src="../images/work/Widespread.png"
                          placeholder="blurred"
                        />
                      </div>
                      <div >
                        <h4>Widespread Technologies</h4>
                      </div>
                    </div>
                    <div className={style.pf_work_description}>
                      <h4>Design Head & Developer</h4>
                      Gathered requirements & presented concepts to the clients. Lead a team of four designers and developers.
                  </div>
                    <div className={style.pf_work_location}>
                      <p>Bangalore, India</p>

                    </div>
                  </div>
                </Cardcontent>
              </Card>
            </div>
            <div className={style.pf_gridify}>
              <Chip className={style.pf_nobg_chip} label="2008 - 2010"  ></Chip>
              <Card className={style.pf_mainCard}>
                <Cardcontent>
                  <div className={style.pf_work_container_grid}>
                    <div className={style.pf_work_header_grid}>
                      <div className={style.pf_work_logo}>
                        <StaticImage className={classNames()}
                          alt="Slalom"
                          src="../images/work/Momenta.png"
                          placeholder="blurred"
                        />
                      </div>
                      <div >
                        <h4>Momenta Global</h4>
                      </div>
                    </div>
                    <div className={style.pf_work_description}>
                      <h4>Design Trainee</h4>
                      Assisted in web design and development.
Took part in bug testing before product deployment.
                  </div>
                    <div className={style.pf_work_location}>
                      <p>Bangalore, India</p>

                    </div>
                  </div>
                </Cardcontent>
              </Card>
            </div>

          </div>

          <Container maxWidth="lg">
            <div className={style.pf_two_block_grid_fixed_width}>
              <div className={classNames(style.pf_white_all, style.pf_2block_icon_text)}>
                <div>
                  <School></School>
                </div>
                <div>
                  <h3>MS - Human Computer Interaction</h3>
              Indiana University, Bloomington
              <p>2014 - 2016</p>
                </div>
              </div>
              <div className={classNames(style.pf_vr, style.pf_white_bg)}></div>
              <div className={classNames(style.pf_white_all, style.pf_2block_icon_text)}>
                <div>
                  <School></School>
                </div>
                <div>
                  <h3>BE - Mechanical Engineering</h3>
              VTU, India <p>2002 - 2006</p>
                </div>
              </div>
            </div>
          </Container>
        </Container>

      </Container>





      <Container id="motDesigning" maxWidth={false} className={classNames(style.pf_block_pads, style.pf_white_bg, style.pf_nopad_LR)}>
        <Sectionheaderlight caption="What I Do" label=" When I am not designing" className={style.pf_all_white_text} ></Sectionheaderlight>
        {/* // hobbies code */}
        <Container maxWidth="false" className={style.pf_nopad_LR}>
          <div className={classNames(style.pf_symetric_grid, style.pf_block_pads_top, style.pf_symetric_grid_2)}>

            <div className={style.pf_gridify}>
              <Card className={style.pf_mainCard}>
                <Cardcontent>
                  <h1>Connect with my inner self</h1>
           Once in a while, I let my mind take me on a creative journey through sketching intricate forms and patterns.
           I am amazed how something that begins as a doodle evolves
           into a concept that resonate with my current emotions.
           </Cardcontent>
              </Card>
            </div>
            <div className={style.pf_gridify}>
              <Card className={style.pf_mainCard}>
                <Cardcontent>
                  <StaticImage className={classNames(style.pf_img_banner)}
                    alt="Gatsby G Logo"
                    src="../images/tatoo/1.jpg"
                    placeholder="blurred"
                  />
                </Cardcontent>
              </Card>
            </div>
            <div className={style.pf_gridify}>
              <Card className={style.pf_mainCard}>
                <Cardcontent>
                  <StaticImage className={classNames(style.pf_img_banner)}
                    alt="Gatsby G Logo"
                    src="../images/tatoo/2.jpg"
                    placeholder="blurred"
                  />
                </Cardcontent>
              </Card>
            </div>
            <div className={style.pf_gridify}>
              <Card className={style.pf_mainCard}>
                <Cardcontent>
                  <StaticImage className={classNames(style.pf_img_banner)}
                    alt="Gatsby G Logo"
                    src="../images/tatoo/3.jpg"
                    placeholder="blurred"
                  />
                </Cardcontent>
              </Card>
            </div>
            <div className={style.pf_gridify}>
              <Card className={style.pf_mainCard}>
                <Cardcontent>
                  <StaticImage className={classNames(style.pf_img_banner)}
                    alt="Gatsby G Logo"
                    src="../images/tatoo/4.jpg"
                    placeholder="blurred"
                  />
                </Cardcontent>
              </Card>
            </div>
            <div className={style.pf_gridify}>
              <Card className={style.pf_mainCard}>
                <Cardcontent>
                  <StaticImage className={classNames(style.pf_img_banner)}
                    alt="Gatsby G Logo"
                    src="../images/tatoo/5.jpg"
                    placeholder="blurred"
                  />
                </Cardcontent>
              </Card>
            </div>


          </div>

        </Container>
        <Container maxWidth="xs" className={style.pf_block_pads}>
          <StaticImage
            alt="Gatsby G Logo"
            src="../images/guitar.png"
            placeholder="blurred"
          />
        </Container>
        <Container maxWidth={"lg"} className={style.pf_block_pads_small}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} lg={4} className={style.pf_pic_grid}>
              <div>
                <StaticImage className={classNames(style.pf_img_banner1)}
                  alt="Gatsby G Logo"
                  src="../images/escape.jpg"
                  placeholder="blurred"
                />
              </div>
              <div>
                Escape rooms have become a weekly thing for me and my friends! We love the challenge and the way we all come together to escape ( boredom included )
             </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} className={style.pf_pic_grid} >
              <div>
                <StaticImage className={classNames(style.pf_img_banner1)}
                  alt="Gatsby G Logo"
                  src="../images/paint.jpg"
                  placeholder="blurred"
                />
              </div>
              <div>
                I am fascinated by the technology that lets us bring our imagination to life. Recently started dabbling with digital painting and I love it! Original by <a href="https://twitter.com/gorosart/status/1064743026692521984" target="_blank">Goro Fujita</a>

              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} className={style.pf_pic_grid} >
              <div>
                <StaticImage className={classNames(style.pf_img_banner1)}
                  alt="Gatsby G Logo"
                  src="../images/kayak.jpg"
                  placeholder="blurred"
                />
              </div>
              <div>
                Here's me trying to be a bit more adventurous. Even with a few hiccups, I think I am warming up to it somehow :)

             </div>
            </Grid>
          </Grid>
        </Container>


      </Container>



      <Container id="connect" maxWidth={false} className={classNames(style.pf_no_margin, style.pf_full_width)}>
        <div className={classNames(style.pf_footer_grid, style.pf_all_white_text)}>
          <div className={style.pf_img_wrap}>
            <StaticImage className={classNames(style.pf_img_banner)}
              alt="Gatsby G Logo"
              src="../images/footer.jpg"
              placeholder="blurred"
            />
          </div>
          <div className={classNames(style.pf_blue_panel, style.pf_block_pads_all)}>
            <h3>I really appreciate your time & attention. Let’s stay connected!</h3>
            <Contact></Contact>
          </div>

        </div>
      </Container>
      <Footer />


    </div>
    
  )
}


